<template>
  <el-dialog
      title="添加子菜单"
      :visible.sync="addChildData.isOpen"
      width="550px"
      :before-close="closeHandler"
      center
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="菜单名称" prop="menuName">
        <el-input v-model="formData.menuName"></el-input>
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType">
        <el-select v-model="formData.menuType" placeholder="请选择菜单类型">
          <el-option v-for="(item,index) in menuTypeList"
                     :label="item"
                     :value="(index+1)"
                     :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <el-input v-model="formData.icon"></el-input>
      </el-form-item>
      <el-form-item label="前端路径" v-if="formData.menuType === 1" prop="path">
        <el-input v-model="formData.path"></el-input>
      </el-form-item>
      <el-form-item label="权限标识" v-if="formData.menuType === 2" prop="permission">
        <el-input v-model="formData.permission"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sortNum">
        <el-input-number v-model="formData.sortNum" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="上级菜单" prop="pid">
        <el-select v-model="formData.pid" placeholder="请选择上级菜单" disabled>
          <el-option v-for="(item,index) in menuList" :key="index" :label="item.menuName"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeHandler" round>取 消</el-button>
      <el-button type="primary" @click="save" round>确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { listAllMenu, saveMenu } from '@/api/login.js'

export default {
  props: {
    addChildData: {
      pid: String,
      isOpen: Boolean
    }
  },
  data () {
    return {
      formData: {
        menuName: '',
        menuType: '',
        icon: '',
        path: '',
        permission: '',
        sortNum: 0,
        pid: ''
      },
      rules: {
        menuName: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }]
      },
      menuTypeList: ['菜单', '权限'],
      menuList: []
    }
  },
  watch: {
    'addChildData.isOpen' () {
      if (this.addChildData.isOpen) {
        this.formData.pid = this.addChildData.pid
        listAllMenu().then(res => {
          this.menuList = []
          this.menuList.push({
            id: '0',
            menuName: '无上级'
          })
          res.data.forEach(item => {
            this.menuList.push(item)
          })
        })
      }
    }
  },
  methods: {
    save () {
      this.$refs.form.validate(validate => {
        if (!validate) return false
        this.$confirm('是否添加子菜单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          type: 'warning'
        }).then(() => {
          saveMenu(this.formData).then(() => {
            this.$emit('closeDialog', {
              isOpen: false,
              type: 'success'
            })
            this.$refs.form.resetFields()
            this.$message({
              message: '添加子菜单成功',
              type: 'success'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改菜单'
          })
        })
      })
    },
    closeHandler () {
      this.$refs.form.resetFields()
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  padding-right: 50px;
}
</style>
